import styled from 'styled-components';

// Components
import JournalHeader from './JournalHeader';
import JournalContent from './JournalContent';

// Assets
import notebook from '../assets/images/vintage-notebook.png';

// Styled Elements
const JournalContainer = styled.div`
  background-image: url(${notebook});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  min-height: calc(100vh - 4em);
  position: relative;
  width: 100%;

  @media screen and (max-width: 1024px) {
    background-position: -2em top;
    background-size: inherit;
  }
`;

function Journal() {
  return (
    <JournalContainer>
      <JournalHeader />
      <JournalContent />
    </JournalContainer>
  );
}

export default Journal;
