const GhostData = [
  {
    id: "banshee",
    type: "Banshee",
    evidence: ["dots", "orb", "ultraviolet"],
    description: "A Banshee is a natural hunter and will attack anything. It has been known to stalk its prey one at a time until making its kill",
    strengths: "A Banshee will only target one person at a time.",
    weaknesses: "Banshees fear the Crucifix and will be less aggressive when near one."
  },
  {
    id: "demon",
    type: "Demon",
    evidence: ["writing", "ultraviolet", "freezing_temps"],
    description: "A Demon is one of the worst Ghosts you can encounter. It has been known to attack without a reason.",
    strengths: "Demons will attack more often than any other Ghost.",
    weaknesses: "Asking a Demon successful questions on the Ouija Board won't lower the user's sanity."
  },
  {
    id: "deogen",
    type: "Deogen",
    evidence: ["dots", "spirit_box", "writing"],
    description: "Sometimes surrounded by an endless fog, Deogen have been eluding ghost hunters for years. These ghosts have been reported to find even the most hidden prey, before stalking them into exhaustion.",
    strengths: "Deogen constantly sense the living. You can run but you can't hide.",
    weaknesses: "Deogen require a lot of energy to form and will move very slowly."
  },
  {
    id: "goryo",
    type: "Goryo",
    evidence: ["dots", "emf", "ultraviolet"],
    description: "Using a video camera is the only way to view a Goryo, when it passes through a DOTS projector.",
    strengths: "A Goryo will usually only show itself on camera if there are no people nearby.",
    weaknesses: "They are rarely seen far from their place of death."
  },
  {
    id: "hantu",
    type: "Hantu",
    evidence: ["orb", "ultraviolet", "freezing_temps"],
    description: "A rare ghost that can be found in hot climates. They are known to attack more often in cold weather.",
    strengths: "Moves faster in colder areas.",
    weaknesses: "Moves slower in warmer areas."
  },
  {
    id: "jinn",
    type: "Jinn",
    evidence: ["emf", "ultraviolet", "freezing_temps"],
    description: "A Jinn is a territorial ghost that will attack when threatened. It has also been known to be able to travel at significant speed.",
    strengths: "A Jinn will travel at a faster speed if its victim is far away.",
    weaknesses: "Turning off the locations power source will prevent the Jinn from using its ability."
  },
  {
    id: "mare",
    type: "Mare",
    evidence: ["writing", "orb", "spirit_box"],
    description: "A Mare is the source of all nightmares, making it most powerful in the dark.",
    strengths: "A Mare will have an increased chance to attack in the dark.",
    weaknesses: "Turning the lights on around the Mare will lower its chance to attack."
  },
  {
    id: "moroi",
    type: "Moroi",
    evidence: ["freezing_temps", "spirit_box", "writing"],
    description: "Moroi have risen from the grave to drain energy from the living. They have been known to place curses on their victims, curable only by antidotes or moving very far away.",
    strengths: "The weaker their victims, the stronger the Moroi becomes.",
    weaknesses: "Moroi suffer from hyperosmia, weakening them for longer periods."
  },
  {
    id: "myling",
    type: "Myling",
    evidence: ["writing", "emf", "ultraviolet"],
    description: "A Myling is a very vocal and active ghost. They are rumoured to be quiet when hunting their prey.",
    strengths: "A Myling is known to be quieter when hunting.",
    weaknesses: "Mylings more frequently make paranormal sounds."
  },
  {
    id: "obake",
    type: "Obake",
    evidence: ["emf", "ultraviolet", "orb"],
    description: "Obake are terrifying shape-shifters, capable of taking on many forms. They have been seen taking on humanoid shapes to attract their prey.",
    strengths: "When interacting with the environment, an Obake will rarely leave a trace.",
    weaknesses: "Sometimes this ghost will shapeshift, leaving behind unique evidence."
  },
  {
    id: "oni",
    type: "Oni",
    evidence: ["dots", "emf", "freezing_temps"],
    description: "Onis are a cousin to the Demon and possess extreme strength. There have been rumours that they become more active around their prey.",
    strengths: "Onis are more active when people are nearby and have been seen moving objects at great speed.",
    weaknesses: "Being more active make the Oni easier to find and identify."
  },
  {
    id: "onryo",
    type: "Onryo",
    evidence: ["freezing_temps", "orb", "spirit_box"],
    description: "The Onryo is often referred to as \"The Wrathful Spirit.\" It steals souls from dying victims' bodies to seek revenge. This ghost has been known to fear any form of fire, and will do anything to be far from it.",
    strengths: "Extinguishing a flame can cause an Onryo to attack.",
    weaknesses: "When threatened, this ghost will be less likely to attack."
  },
  {
    id: "phantom",
    type: "Phantom",
    evidence: ["dots", "ultraviolet", "spirit_box"],
    description: "A Phantom is a ghost that can possess the living, most commonly summoned by a Ouija Board. It also induces fear into those around it.",
    strengths: "Looking at a Phantom will considerably drop your sanity.",
    weaknesses: "Taking a photo of the Phantom will make it temporarily disappear."
  },
  {
    id: "poltergeist",
    type: "Poltergeist",
    evidence: ["writing", "ultraviolet", "spirit_box"],
    description: "One of the most famous Ghosts, a Poltergeist, also known as a noisy ghost can manipulate objects around it to spread fear into its victims.",
    strengths: "A Poltergeist can throw huge amounts of objects at once.",
    weaknesses: "A Poltergeist is almost ineffective in an empty room."
  },
  {
    id: "raiju",
    type: "Raiju",
    evidence: ["dots", "emf", "orb"],
    description: "A Raiju is a demon that thrives on electrical current. While generally calm, they can become agitated when overwhelmed with power.",
    strengths: "A Raiju can siphon power from nearby electrical devices, making it move faster.",
    weaknesses: "Raiju are constantly disrupting electronic equipment, making it easier to track when attacking."
  },
  {
    id: "revenant",
    type: "Revenant",
    evidence: ["writing", "orb", "freezing_temps"],
    description: "A Revenant is a slow but violent ghost that will attack indiscriminately. It has been rumoured to travel at a significantly high speed when hunting.",
    strengths: "A Revenant will travel at a significantly faster speed when hunting a victim.",
    weaknesses: "Hiding from the Revenant will cause it to move very slowly."
  },
  {
    id: "shade",
    type: "Shade",
    evidence: ["writing", "emf", "freezing_temps"],
    description: "A Shade is known to be a Shy Ghost. There is evidence that a Shade will stop all paranormal activity if there are multiple people nearby.",
    strengths: "Being shy means the Ghost will be harder to find.",
    weaknesses: "The Ghost will not enter hunting mode if there are multiple people nearby."
  },
  {
    id: "spirit",
    type: "Spirit",
    evidence: ["writing", "emf", "spirit_box"],
    description: "A Spirit is the most common Ghost you will come across however it is still very powerful and dangerous. They are usually discovered at one of their hunting grounds after an unexplained death.",
    strengths: "None",
    weaknesses: "Using Smudge Sticks on a spirit will stop it attacking for a long period of time."
  },
  {
    id: "thaye",
    type: "Thaye",
    evidence: ["dots", "orb", "writing"],
    description: "Thaye have been known to rapidly age over time, even in the afterlife. From what we've learned, they seem to deteriorate faster while within the presence of the living.",
    strengths: "Upon entering the location, Thaye will become active, defensive and agile.",
    weaknesses: "Thaye will weaken over time, making them weaker, slower, and less aggressive."
  },
  {
    id: "the_mimic",
    type: "The Mimic",
    evidence: ["ultraviolet", "freezing_temps", "spirit_box"],
    description: "The mimic is an elusive, mysterious, copycat ghost that mirrors traits and behaviours from others, including other ghost types.",
    strengths: "We're unsure what this ghost is capable of. Be careful.",
    weaknesses: "Several reports have noted ghost orb sightings near mimics."
  },
  {
    id: "the_twins",
    type: "The Twins",
    evidence: ["emf", "freezing_temps", "spirit_box"],
    description: "These ghosts have been reported to mimic each other's actions. They alternate their attacks to confuse their prey.",
    strengths: "Either Twin can be angered and initiate an attack on their prey.",
    weaknesses: "The Twins will often interact with the environment at the same time."
  },
  {
    id: "wraith",
    type: "Wraith",
    evidence: ["dots", "emf", "spirit_box"],
    description: "A wraith is one of the most dangerous ghosts you will find. It is also the only known Ghost that has the ability of flight and has sometimes been known to travel through walls.",
    strengths: "Wraiths almost never touch the ground meaning it can't be tracked by footsteps.",
    weaknesses: "Wraiths have a toxic reaction to Salt."
  },
  {
    id: "yokai",
    type: "Yokai",
    evidence: ["dots", "orb", "spirit_box"],
    description: "A common type of ghost that is attracted to human voices. They can usually be found haunting family homes.",
    strengths: "Talking near a Yokai will anger it and cause it to attack more often.",
    weaknesses: "While hunting, it can only hear voices close to it."
  },
  {
    id: "yurei",
    type: "Yurei",
    evidence: ["dots", "orb", "freezing_temps"],
    description: "A Yurei is a Ghost that has returned to the physical world, usually for the purpose of revenge or hatred.",
    strengths: "Yureis have been known to have a stronger effect on people sanity.",
    weaknesses: "Smudging the Yurei's room will cause it to not wander around the location for a long time."
  }
];

export default GhostData;