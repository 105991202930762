import styled from 'styled-components';

// Styled Elements
const Footer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    background-color: #000000;
    color: #FFFFFF;
    display: inline-block;
    font-size: 0.875em;
    padding: 2em 5%;
    position: relative;
    width: 100%;
  }
`;

const Link = styled.a`
  color: #046B99;
  display: inline-block;
  position: relative;
`;

const Note = styled.p`
  margin-bottom: 1em;
`;

const SmallNote = styled.p`
  font-size: 0.75em;
`;

function MobileFooter() {
  return (
    <Footer>
      <Note>
        Made by <Link href="https://github.com/calej17" target="_blank" title="User calej17 on GitHub">@calej17</Link> in appreciation of <Link href="https://kineticgames.co.uk/" target="_blank" title="Kinetic Games">Kinetic Games</Link> and their hit ghost hunting game <Link href="https://store.steampowered.com/app/739630/Phasmophobia/" target="_blank" title="Phasmophobia on Steam">Phasmophobia</Link>.
      </Note>
      <SmallNote>
        I do not claim ownership of any of the content contained herein; all appropriate rights, trademarks, etc belong to Kinetic Games. <span className="fas fa-heart"></span>
      </SmallNote>
    </Footer>
  );
}

export default MobileFooter;
