import styled from 'styled-components';

// Components
import Journal from './components/Journal';
import MobileFooter from './components/MobileFooter';

// Styled Elements
const AppContainer = styled.div`
  display: inline-block;
  min-height: calc(100vh - 4em);
  position: relative;
  width: 100%
`;

function App() {
  return (
    <AppContainer className="App">
      <Journal />
      <MobileFooter />
    </AppContainer>
  );
}

export default App;
