import styled from 'styled-components';
import _ from 'lodash';

const icons = {
  dots: "fas fa-braille",
  emf: "fas fa-signal",
  freezing_temps: "fas fa-thermometer-quarter",
  orb: "fas fa-dot-circle",
  spirit_box: "fas fa-volume-up",
  ultraviolet: "fas fa-fingerprint",
  writing: "fas fa-book"
};

// Styled Elements
const Button = styled.button`
  -webkit-appearance: none;
  -khtml-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  align-items: center;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
  display: inline-flex;
  font-family: "Marker";
  font-size: 1.25em;
  padding: 0.5em 0;
  position: relative;

  &:before {
    border: 2px solid #000000;
    border-radius: 0.2em;
    content: "";
    display: inline-block;
    height: 1em;
    margin-right: 0.5em;
    position: relative;
    width: 1em;
  }

  &.active {
    &:after {
      color: #205493;
      content: "X";
      font-family: "Marker";
      font-size: 2em;
      left: 0;
      position: absolute;
      text-transform: uppercase;
    }
  }

  &.invalid {
    opacity: 0.25;

    &:after {
      background-color: #000000;
      content: "";
      height: 2px;
      left: -3%;
      position: absolute;
      width: 106%;
    }
  }

  @media screen and (max-width: 1024px) {
    border: 2px solid;
    border-radius: 0.4em;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    user-select: none;
    width: 46%;

    &:before,
    &.active:after,
    &.invalid:after {
      content: none;
    }

    &.active {
      color: #205493;
    }
  }

  span {
    display: none;

    @media screen and (max-width: 1024px) {
      display: inline-block;
      font-size: 2em;
      padding: 0.5em 1em;
      position: relative;
      width: 100%;
    }
  }
`;

function EvidenceLink({ active, extraClasses, evidenceId, invalid, linkText, onClick }) {
  let classes = _.compact(_.flatten(["evidence-link", extraClasses]));

  if (active) classes.push("active");
  if (invalid) classes.push("invalid");

  return (
    <Button
      className={classes.join(" ").trim()}
      data-id={evidenceId}
      disabled={invalid}
      onClick={onClick}
      title={linkText}
    >
      <span className={icons[evidenceId]}></span>
      {linkText}
    </Button>
  );
}

export default EvidenceLink;
