// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAipFJgHcHtu85yb3fjeSGMVv-RlzewLVc",
  authDomain: "ghost-journal-74503.firebaseapp.com",
  projectId: "ghost-journal-74503",
  storageBucket: "ghost-journal-74503.appspot.com",
  messagingSenderId: "54192208519",
  appId: "1:54192208519:web:d77b685c63b811d2fb5622",
  measurementId: "G-E5DJTNC5C7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
