import React, { useState } from 'react';
import _ from 'lodash';

// Styles
import '../assets/stylesheets/JournalContent.css';

// Components
import EvidenceLink from './EvidenceLink';
import GhostType from './GhostType';
import Sticky from './Sticky';

// Data
import GhostData from '../data/GhostData';

const evidenceOptions = [
  { id: 'dots', evidenceName: 'D.O.T.S Projector' },
  { id: 'emf', evidenceName: 'EMF Level 5' },
  { id: 'freezing_temps', evidenceName: 'Freezing Temperatures' },
  { id: 'orb', evidenceName: 'Ghost Orb' },
  { id: 'spirit_box', evidenceName: 'Spirit Box' },
  { id: 'ultraviolet', evidenceName: 'Ultraviolet' },
  { id: 'writing', evidenceName: 'Ghost Writing' }
];

function JournalContent() {
  const [selectedEvidence, setSelectedEvidence] = useState([]);
  const [ghosts, setGhosts] = useState([]);
  const [invalidEvidence, setInvalidEvidence] = useState([]);

  function handleEvidenceChange(evidence) {
    let index = selectedEvidence.indexOf(evidence);
    let newEvidence = [...selectedEvidence];

    if (index === -1) {
      if (newEvidence.length === 3) return false;
      newEvidence.push(evidence);
    } else {
      newEvidence.splice(index, 1);
    }

    setSelectedEvidence(newEvidence);

    const newGhosts = filterGhostTypes(newEvidence, []);
    setGhosts(newGhosts);

    setInvalidEvidence(filterEvidence(newEvidence, newGhosts));
  }

  function filterGhostTypes(present, notPresent) {
    return Object.values(
      _.pickBy(
        _.omitBy(GhostData, (ghost) =>
          ghost.evidence.some((r) => notPresent.indexOf(r) >= 0)
        ),
        (ghost) => present.every((r) => ghost.evidence.indexOf(r) >= 0)
      )
    );
  }

  function filterEvidence(newEvidence, newGhosts) {
    let ids = _.flatMap(evidenceOptions, (evidence) => evidence.id);
    let evidences = _.uniq(_.flatMap(newGhosts, (ghost) => ghost.evidence), newEvidence)

    return Object.values(
      _.difference(ids, evidences)
    );
  }

  return (
    <section className="journal-content">
      <div className="evidence-list">
        <h3>Evidence Options</h3>
        {_.sortBy(evidenceOptions, "evidenceName").map((evidence) => (
          <EvidenceLink
            active={selectedEvidence.includes(evidence.id)}
            evidenceId={evidence.id}
            // extraClasses=""
            invalid={invalidEvidence.includes(evidence.id)}
            key={evidence.id}
            linkText={evidence.evidenceName}
            onClick={() => handleEvidenceChange(evidence.id)}
          />
        ))}

        <Sticky />
      </div>
      <div className="ghost-options">
        <h3>Ghost Possibilities</h3>
        {selectedEvidence.length
        ? ghosts.map((ghost) => (
            <GhostType
              description={ghost.description}
              evidence={ghost.evidence}
              key={ghost.id}
              selectedEvidence={selectedEvidence}
              strengths={ghost.strengths}
              type={ghost.type}
              weaknesses={ghost.weaknesses}
            />
          ))
        : <p className="ghost-options-empty">
            Start collecting evidence to filter ghost types.
          </p>
        }
      </div>
    </section>
  );
}

export default JournalContent;
