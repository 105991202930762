import styled from 'styled-components';

// Styled Elements
const HeaderElement = styled.header`
  color: #000000;
  display: inline-block;
  font-family: 'Marker';
  padding: 2em 5%;
  position: relative;
  width: 100%;
`;

const H1 = styled.h1`
  @media screen and (max-width: 1024px) {
    font-size: 2.5em;
  }
`;

const ResetLink = styled.a`
  border: 1px solid;
  border-radius: 0.4em;
  color: #205493;
  display: inline-block;

  @media screen and (max-width: 1024px) {
    padding: 0.5em 1em;
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    padding: 1em 2em;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
  }
`;

function Header() {
  return (
    <HeaderElement>
      <H1>Ghost Journal <span className="fas fa-ghost"></span></H1>
      <ResetLink href="/" title="Reset evidence selections">Reset</ResetLink>
    </HeaderElement>
  );
}

export default Header;
