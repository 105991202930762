import styled from 'styled-components';

// Assets
import sticky from '../assets/images/sticky.png';

// Styled Elements
const StickyContainer = styled.div`
  background-image: url(${sticky});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  font-family: 'Marker';
  font-size: 0.875em;
  height: 30em;
  line-height: 1.4;
  margin-top: 3em;
  padding: 4em 4em 9em;
  position: relative;
  width: 30em;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const Link = styled.a`
  color: #205493;
  display: inline-block;
  position: relative;
`;

const Note = styled.p`
  margin-bottom: 2em;
`;

const SmallNote = styled.p`
  font-size: 0.75em;
`;

function Sticky() {
  return (
    <StickyContainer>
      <Note>
        Made by <Link href="https://github.com/calej17" target="_blank" title="User calej17 on GitHub">@calej17</Link> in appreciation of <Link href="https://kineticgames.co.uk/" target="_blank" title="Kinetic Games">Kinetic Games</Link> and their hit ghost hunting game <Link href="https://store.steampowered.com/app/739630/Phasmophobia/" target="_blank" title="Phasmophobia on Steam">Phasmophobia</Link>.
      </Note>
      <SmallNote>
        I do not claim ownership of any of the content contained herein; all appropriate rights, trademarks, etc belong to Kinetic Games. <span className="fas fa-heart"></span>
      </SmallNote>
    </StickyContainer>
  );
}

export default Sticky;
