import styled from 'styled-components';
import _ from 'lodash';

// Assets
import paper from '../assets/images/paper-texture.jpg';

// Styled Elements
const Ghost = styled.div`
  background-image: url(${paper});
  border-radius: 0.4em;
  // border: 2px solid #000000;
  box-shadow: 0.0em 0.0em 0.2em -0.1em #000000;
  display: inline-block;
  flex-wrap: wrap;
  margin-bottom: 1em;
  padding: 1em;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1024px) {}
`;

const FullBox = styled.div`
  position: relative;
  width: 100%;
`;

const GhostHeader = styled(FullBox)`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
`;

const GhostName = styled.span`
  font-family: 'Marker';
  font-size: 1.25em;
  margin-right: 0.5em;
`;

const GhostEvidence = styled.span`
  font-size: 0.875em;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 0.65em;
  }

  span {
    border: 2px solid transparent;
    font-weight: bold;
    margin: 0 0.25em;
    padding: 0 0.25em;

    &.remaining {
      border-color: #205493;
      border-radius: 0.2em;
      color: #205493;
    }
  }
`;

const GhostDetails = styled(FullBox)`
  display: inline-block;
  margin-top: 0.5em;
`;

const GhostDescription = styled.p`
  width: 100%;
`;

const GhostAttribute = styled.p`
  font-size: 0.875em;
  font-weight: bold;
`;

const GhostStrength = styled(GhostAttribute)`
  color: #2E8540;
`;

const GhostWeakness = styled(GhostAttribute)`
  color: #CD2026;
`;

function GhostType({ description, evidence, type, strengths, weaknesses, selectedEvidence }) {
  let evidenceList = _.sortBy(evidence).map((value, index) =>
    <span className={!selectedEvidence.includes(value) ? "remaining" : undefined} key={index}>{value.replace("_", " ")}</span>
  );

  return (
    <Ghost>
      <GhostHeader>
        <GhostName>{type}</GhostName>
        <GhostEvidence>{evidenceList}</GhostEvidence>
      </GhostHeader>

      {selectedEvidence.length > 1 &&
        <GhostDetails>
          <GhostDescription>{description}</GhostDescription>
          <GhostStrength>+ {strengths}</GhostStrength>
          <GhostWeakness>– {weaknesses}</GhostWeakness>
        </GhostDetails>
      }
    </Ghost>
  );
}

export default GhostType;
